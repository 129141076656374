<template>
  <div>
    <el-form :inline="true" :model="queryFormModel" class="demo-form-inline">
      <el-form-item label="商户">
        <el-select v-model="queryFormModel.merchantId" placeholder="请选择" clearable filterable @change="merchantQueryChanged">
          <el-option v-for="item in merchantList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理">
          <el-select v-model="queryFormModel.proxyId" placeholder="请选择" clearable filterable>
            <el-option v-for="item in proxyList4Query" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="日期范围">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryList">查 询</el-button>
      </el-form-item>
    </el-form>

    <el-pagination 
      style="margin-bottom: 5px; float: right;"
      background 
      layout="prev, pager, next" 
      :current-page.sync="page" 
      :page-size.sync="rows" 
      :total="tableTotal" 
      @current-change="currentPageChanged">
    </el-pagination>
    
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="ID" width="200"></el-table-column>
      <el-table-column prop="merchantName" label="所属商户" width="300"></el-table-column>
      <el-table-column prop="proxyName" label="所属代理" width="300"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="viewTaskScales(scope.row.id)">查看传秤状态</el-button>
          <el-button type="text" size="small" @click="viewTaskDetails(scope.row.id)">查看商品明细</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer title="传称状态" :visible.sync="taskScalesDrawerVisible" direction="ltr" :size="700">
      <el-table :data="taskScales" border style="width: 100%">
        <el-table-column prop="scaleName" label="称设备" width="250"></el-table-column>
        <el-table-column prop="executeState" label="执行状态" width="100">
          <template slot-scope="scope">
            <i v-if="scope.row.executeState == '0'" class="el-icon-info" style="color:#909399">未执行</i>
            <i v-else-if="scope.row.executeState == '1'" class="el-icon-warning" style="color:#E6A23C">执行中</i>
            <i v-else-if="scope.row.executeState == '2'" class="el-icon-success" style="color:#67C23A">执行成功</i>
            <i v-else-if="scope.row.executeState == '3'" class="el-icon-error" style="color:#F56C6C">执行失败</i>
          </template>
        </el-table-column>
        <el-table-column prop="executeResult" label="失败原因" width="300"></el-table-column>
      </el-table>
    </el-drawer>

    <el-drawer title="任务商品" :visible.sync="taskDetailsDrawerVisible" direction="ltr" :size="1200">
      <el-table :data="taskDetails" border style="width: 100%">
        <el-table-column prop="itemCode" label="商品编码"></el-table-column>
        <el-table-column prop="itemBarcode" label="商品条码"></el-table-column>
        <el-table-column prop="itemName" label="商品名称"></el-table-column>
        <el-table-column prop="unitName" label="单位" width="80"></el-table-column>
        <el-table-column prop="pricingMode" label="计价方式" width="100">
          <template slot-scope="scope">
            {{pricingModeEnum[scope.row.pricingMode]}}
          </template>
        </el-table-column>
        <el-table-column prop="retailPrice" label="零售价" width="80"></el-table-column>
        <el-table-column prop="promotePrice" label="促销价" width="80"></el-table-column>
        <el-table-column prop="vipPrice" label="会员价" width="80"></el-table-column>
        <el-table-column prop="vipPrice1" label="会员价1" width="80"></el-table-column>
        <el-table-column prop="vipPrice2" label="会员价2" width="80"></el-table-column>
        <el-table-column prop="validDays" label="保质期天数" width="100"></el-table-column>
        <el-table-column prop="memo" label="备注"></el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import httpClient from '../httpClient.js'
export default {
  name: "TaskList",
  data() {
    return {
      page: 1,
      rows: 10,
      tableTotal: 0,
      tableData: [],
      merchantList: [],
      proxyList4Query: [],
      queryFormModel: {},
      dateRange: [],
      taskScalesDrawerVisible: false,
      taskDetailsDrawerVisible: false,
      taskScales: [],
      taskDetails: [],
      pricingModeEnum: {
        '0': '普通商品',
        '1': '称重',
        '2': '计数',
      }
    }
  },
  methods: {
    queryList() {
      let data = {
        page: this.page,
        rows: this.rows,
        params: this.queryFormModel
      }
      httpClient.post("/api/task/query", data).then((response) => {
        this.tableTotal = response.data.data.total
        this.tableData = response.data.data.list
      })
    },
    currentPageChanged() {
      this.queryList()
    },
    queryAllMerchantList() {
      let params = { rows: 1000 }
      httpClient.post("/api/merchant/query", params).then((response) => {
        this.merchantList = response.data.data.list
      })
    },
    merchantQueryChanged(merchantId) {
      let params = {
        rows: 1000,
        params: {
          merchantId: merchantId
        }
      }
      httpClient.post("/api/proxy/query", params).then((response) => {
        this.proxyList4Query = response.data.data.list
      })
    },
    viewTaskDetails(taskId) {
      this.taskDetailsDrawerVisible = true
      httpClient.get(`/api/task/${taskId}/details`).then((response) => {
        this.taskDetails = response.data.data
      })
    },
    viewTaskScales(taskId) {
      this.taskScalesDrawerVisible = true
      httpClient.get(`/api/task/${taskId}/scales`).then((response) => {
        this.taskScales = response.data.data
      })
    }
  },
  mounted() {
    this.queryList()
    this.queryAllMerchantList()
    this.merchantQueryChanged()
  },
  watch: {
    dateRange(newDateRange) {
      if (newDateRange) {
        this.queryFormModel.fromDate = newDateRange[0]
        this.queryFormModel.toDate = newDateRange[1]
      }else  {
        this.queryFormModel.fromDate = null
        this.queryFormModel.toDate = null
      }
    }
  }
};
</script>