<style>
 .header-input { width: 400px; }
 .diff-row { cursor: pointer; }
 .diff-row:hover { background-color: #d9e0e2; }
</style>
<template>
    <div>
        <el-container>
            <el-header>
                <el-form :inline="true" :model="headerFormModel">
                    <el-form-item label="历史版本">
                        <el-input v-model="headerFormModel.url1" placeholder="swagger-resources url" class="header-input"></el-input>
                    </el-form-item>
                    <el-form-item label="新版本">
                        <el-input v-model="headerFormModel.url2" placeholder="swagger-resources url" class="header-input"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleHeaderFormSubmit" v-loading.fullscreen.lock="loading">下载并分析</el-button>
                    </el-form-item>
                </el-form>
            </el-header>
            <el-container>
                <el-aside width="1000px">
                    <el-checkbox-group v-model="checkList">
                        <el-checkbox label="api-deleted">已删除</el-checkbox>
                        <el-checkbox label="api-inserted">新增接口</el-checkbox>
                        <el-checkbox label="medhod-changed">方法修改</el-checkbox>
                        <el-checkbox label="request-changed">请求修改</el-checkbox>
                        <el-checkbox label="response-changed">响应修改</el-checkbox>
                    </el-checkbox-group>
                    <div v-for="data in diffDataComputed" :key="data.id" @click="handleDiffDataClick(data)" class="diff-row">
                        <span>
                            <el-tag v-if="data.diffType == 'api-deleted'" type="danger">已删除</el-tag>
                            <el-tag v-else-if="data.diffType == 'api-inserted'" type="success">新增接口</el-tag>
                            <el-tag v-else-if="data.diffType == 'medhod-changed'" type="warning">方法修改</el-tag>
                            <el-tag v-else-if="data.diffType == 'request-changed'" type="info">请求修改</el-tag>
                            <el-tag v-else-if="data.diffType == 'response-changed'">响应修改</el-tag>
                        </span>
                        <span> {{ data.tags }}</span>-<span>{{ data.summary }}</span>
                        <span style="color: #ab0f3a"> {{ data.path }}</span>
                    </div>
                </el-aside>
                <el-main>
                    <div v-if="diffType == 'api-deleted'">
                        <span>该接口已删除</span>
                    </div>
                    <div v-else-if="diffType == 'api-inserted'">
                        <span>新增接口</span>
                    </div>
                    <div v-else-if="diffType == 'medhod-changed'">
                        原方法：{{ diffDetail.originalMethod }}，新方法：{{ diffDetail.newMethod }}
                    </div>
                    <div v-else-if="diffType == 'request-changed'">
                        <el-table
                            :data="diffDetail"
                            style="width: 100%;margin-bottom: 20px;"
                            row-key="id"
                            border
                            default-expand-all
                            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                            <el-table-column prop="name" label="参数名称" width="180"></el-table-column>
                            <el-table-column prop="description" label="说明" width="180"></el-table-column>
                            <el-table-column prop="required1" label="必填"></el-table-column>
                            <el-table-column prop="type1" label="类型"></el-table-column>
                        </el-table>
                    </div>
                    <div v-else-if="diffType == 'response-changed'">
                        <el-table
                            :data="diffDetail"
                            style="width: 100%;margin-bottom: 20px;"
                            row-key="id"
                            border
                            default-expand-all
                            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                            <el-table-column prop="name" label="参数名称" width="180"></el-table-column>
                            <el-table-column prop="description" label="说明" width="180"></el-table-column>
                            <el-table-column prop="type1" label="类型"></el-table-column>
                        </el-table>
                    </div>
                    <div v-else>
                        nothing
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import axios from "axios"
let axiosInstance = axios.create()
export default {
    name: "SwaggerAnalyzer",
    data() {
        return {
            loading: false,
            headerFormModel: {
                url1: "https://retail-admin.cs.kemai.com.cn/mobile/api",
                url2: "https://retail-admin-kf.cloud.kemai.cn/mobile/api"
            },
            showApiDeletedFlag: true,
            showApiInserted: false,
            showMedhodChanged: true,
            showRequestChanged: true,
            showResponseChanged: true,
            checkList: ['api-deleted', 'medhod-changed', 'request-changed', 'response-changed'],
            diffData: [],   
            schema1: {},    // resources、definitions、paths
            schema2: {},

            /**
             * 递归层次，用于解决循环引用带来的无限递归问题（最多递归3层）
             */
            recursionLevel: 0,

            /**
             * 差异类型：
             *  api-deleted
             *  api-inserted
             *  medhod-changed
             *  request-changed
             *  response-changed
             */
            diffType: '',

            /**
             * 差异明细
             *  medhod-changed:     { originalMethod, newMethod }
             *  request-changed:    [{name, description, type1, type2, required1, required2, children}]
             *  response-changed：  [{name, description, type1, type2, children}]
             */
            diffDetail: null
        }
    }, 
    methods: {
        // 下载资源
        async downloadResources(url) {
            const resourcesRes = await axiosInstance.get(url + '/swagger-resources')
            
            let definitions = {}
            let paths = {}
            for (let index = 0; index < resourcesRes.data.length; index++) {
                const resource = resourcesRes.data[index]
                const docRes = await axiosInstance.get(url + resource.location)
                for (const key in docRes.data.definitions) {
                    definitions[key] = docRes.data.definitions[key]
                }
                for (const key in docRes.data.paths) {
                    paths[key] = docRes.data.paths[key]
                }
            }

            return {
                resources: resourcesRes.data,
                definitions,
                paths
            }
        },
        // 获取请求方法
        resoleApiMethod(obj) {
            if (obj.hasOwnProperty("post")) {
                return 'post'
            } else if (obj.hasOwnProperty("get")) {
                return 'get'
            } else if (obj.hasOwnProperty("delete")) {
                return 'delete'
            } else if (obj.hasOwnProperty("put")) {
                return 'put'
            }
        },
        // 检查请求参数变化
        checkRequestChanged(opreation1, opreation2) {
            if (!opreation1.parameters) {
                return false
            }
            for (let index = 0; index < opreation1.parameters.length; index++) {
                const parameter1 = opreation1.parameters[index]
                const p2arr = opreation2.parameters.filter(p => p.name == parameter1.name)
                if (!p2arr || p2arr.length == 0) {
                    return true
                }
                const parameter2 = p2arr[0]

                if (parameter1.required != parameter2.required) {
                    return true
                }
                if (parameter1.schema && parameter2.schema) {
                    this.recursionLevel = 0
                    if (this.recursionCheckSchema(parameter1.schema, parameter2.schema)) {
                        return true
                    }
                }
            }
            return false
        },
        // 构造请求参数变化明细数据
        buildRequestChangedDetail(parameters1, parameters2) {
            for (const p1 in parameters1) {
                
            }
        },
        // 检查响应模型变化
        checkResponseChanged(opreation1, opreation2) {
            if (!opreation1.responses) {
                return false
            }
            for (const key in opreation1.responses) {
                const response1 = opreation1.responses[key]
                const response2 = opreation2.responses[key]
                if (!response2) {
                    return true
                }
                if (response1.schema && response2.schema) {
                    this.recursionLevel = 0
                    if (this.recursionCheckSchema(response1.schema, response2.schema)) {
                        return true
                    }
                }
            }
        },
        // 构造响应模型变化明细数据
        buildResponseChangedDetail(responses1, responses2) {

        },
        // 递归检查模型差异，返回是否存在差异
        recursionCheckSchema(s1, s2) {
            if (this.recursionLevel > 3) {
                return false
            }
            this.recursionLevel += 1

            let definition1 = this.schema1.definitions[s1.originalRef]
            let definition2 = this.schema2.definitions[s2.originalRef]
            if (!definition1 || !definition2) {
                return false
            }
            for (const propertieName in definition1.properties) {
                let propertie1 = definition1.properties[propertieName]
                let propertie2 = definition2.properties[propertieName]
                // 属性被删除
                if (!propertie2) {
                    return true
                }
                // 属性类型变化
                if (propertie1.type != propertie2.type) {
                    return true
                }
                // 属性必填变化
                let required1 = definition1.required && definition1.required.indexOf(propertieName) > -1
                let required2 = definition2.required && definition2.required.indexOf(propertieName) > -1
                if (required1 != required2) {
                    return true
                }
                if (propertie1.type == 'array') {
                    // 对象数组递归判断
                    if (propertie1.items.originalRef) {
                        if (this.recursionCheckSchema(propertie1.items, propertie2.items)) {
                            return true
                        }
                    }
                }else if (propertie1.originalRef) {
                    // 对象递归判断
                    if (this.recursionCheckSchema(propertie1, propertie2)) {
                        return true
                    }
                }
            }

            for (const propertieName in definition2.properties) {
                let propertie1 = definition1.properties[propertieName]
                // 新增属性
                if (!propertie1) {
                    return true
                }
            }
            return false
        },
        // 【下载并分析】按钮，点击事件处理函数
        async handleHeaderFormSubmit() {
            this.loading = true
            this.diffData = []
            this.schema1 = await this.downloadResources(this.headerFormModel.url1)
            this.schema2 = await this.downloadResources(this.headerFormModel.url2)
            
            for (const path in this.schema1.paths) {
                if (path === 'swagger') {
                    continue
                }
                const api1 = this.schema1.paths[path]
                const method1 = this.resoleApiMethod(api1)
                const opreation1 = api1[method1]
                
                let data = { path, tags: opreation1.tags[0], summary: opreation1.summary }

                // 新版中是否存在，不存在则为“已删除”
                const api2 = this.schema2.paths[path]
                if (!api2) {
                    data.diffType = 'api-deleted'
                    this.diffData.push(data)
                    continue
                }
                const method2 = this.resoleApiMethod(api2)
                const opreation2 = api2[method2]

                // 请求方法变更
                if (method1 != method2) {
                    data.diffType = 'medhod-changed'
                    data.diffDetail = { originalMethod: method1, newMethod: method2 }
                    this.diffData.push(data)
                    continue
                }

                // 分析请求参数
                if (this.checkRequestChanged(opreation1, opreation2)) {
                    let rcData = { path, tags: opreation1.tags[0], summary: opreation1.summary }
                    rcData.diffType = 'request-changed'
                    rcData.diffDetail = this.buildRequestChangedDetail(opreation1, opreation2)
                    this.diffData.push(rcData)
                }

                // 分析响应模型
                if (this.checkResponseChanged(opreation1, opreation2)) {
                    data.diffType = 'response-changed'
                    data.diffDetail = this.buildResponseChangedDetail(opreation1, opreation2)
                    this.diffData.push(data)
                }
            }

            for (const path in this.schema2.paths) {
                if (path === 'swagger') {
                    continue
                }

                const api2 = this.schema2.paths[path]
                const method2 = this.resoleApiMethod(api2)
                const opreation2 = api2[method2]

                let data = { path, tags: opreation2.tags[0], summary: opreation2.summary }
                
                // 旧版中是否存在，不存在则为“新增的”
                const api1 = this.schema1.paths[path]
                if (!api1) {
                    data.diffType = 'api-inserted'
                    this.diffData.push(data)
                    continue
                }
            }
            this.loading = false
        },
        handleDiffDataClick(data) {
            this.diffType = data.diffType
            this.diffDetail = data.diffDetail
        }
    },
    computed: {
        diffDataComputed() {
            return this.diffData.filter(i => this.checkList.indexOf(i.type) > -1)
        }
    }
}
</script>